import React from "react"
import { useTransition, animated, useSpring} from ".."

import { ILoder } from "./model";


const Loader = (props: ILoder) => {
    
    const [items, set] = React.useState(["L", "O","A", "D", "I", "N","G"])

    const line = "line"

    const transitions1 = useTransition(items, item => item, {
        from: { transform: 'translate3d(0,-40px,0)', opacity: 0 },
        enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
        leave: { transform: 'translate3d(0,-40px,0)' },
    })

    const spring = useSpring({
        to: [{width: "100%", color: '#ffaaee'}, {opacity: 0, color: 'rgb(14,26,19)'}],
        from: {width: "0%"}
      })

    return (
    <div className="flex flex-col w-1/3"> 
        <div className="flex flex-row">   
            {   
                transitions1.map(({ item, props, key }) => { return <animated.div key={key} style={props}>{item}</animated.div>}) 
            }
        </div>
        {
            <div className="w-full">
                <animated.div  style={spring} className="h-4 bg-blue-500"/>
            </div>
        }
    </div>)
}

export default Loader;