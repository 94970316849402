import React from "react"

import "./styles.css"
import { IScrollProps } from "./model"

const WithScroll = (props: IScrollProps) => (
  <div
    style={{
      overflowX: props.hideScrollX ? "hidden" : "auto",
      overflowY: props.hideScrollY ? "hidden" : "auto",
      maxHeight: props.maxHeight || 400,
      ...props.style,
    }}
    className={["customScroll", props.className].join(" ")}
  >
    {props.children}
  </div>
)

export default WithScroll
