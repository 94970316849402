import { atom } from "recoil"


const theme = atom({
    key:"theme",
    default:"dark"
})

const state  = {
    theme
}

export default state