import React from 'react'

import { ISwitchProps } from './model'

import "./style.css"

export default (props: ISwitchProps) => {
    return (
        <label className="switch">
            <input type="checkbox" checked={props.checked} onChange={(e)=>{props.onChange(e.target.checked)}} name="theme-slider" aria-label="theme-slider"/>
            <span className="slider round"></span>
        </label>
    )
}
