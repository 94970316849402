import { Icon as _Icon} from '@iconify/react';
import sharpHome from '@iconify/icons-ic/sharp-home';
import aboutDotMe from '@iconify/icons-simple-icons/about-dot-me';
import settingIcon from '@iconify/icons-uil/setting';
import codeSlash from '@iconify/icons-bi/code-slash';
import mailOutlined from '@iconify/icons-ant-design/mail-outlined';
import facebookIcon from '@iconify-icons/logos/facebook';
import twitterIcon from '@iconify-icons/logos/twitter';
import githubIcon from '@iconify-icons/logos/github-icon';
import linkedinIcon from '@iconify-icons/logos/linkedin-icon';
import facebookRect from '@iconify-icons/brandico/facebook-rect';
import twitterSquare from '@iconify-icons/fa/twitter-square';
import linkedinRect from '@iconify-icons/brandico/linkedin-rect';
import arrowDown from '@iconify-icons/simple-line-icons/arrow-down';
import javascriptIcon from '@iconify-icons/simple-icons/javascript';
import reactjsFill from '@iconify-icons/ri/reactjs-fill';
import typescriptIcon from '@iconify-icons/cib/typescript';
import bxlNodejs from '@iconify-icons/bx/bxl-nodejs';
import nextjsOutline from '@iconify-icons/teenyicons/nextjs-outline';
import nextjsIcon from '@iconify-icons/logos/nextjs';
import electronIcon from '@iconify-icons/cib/electron';
import html5Icon from '@iconify-icons/la/html5';
import css3Outline from '@iconify-icons/teenyicons/css3-outline';
import bxMap from '@iconify-icons/bx/bx-map';
import mapboxIcon from '@iconify-icons/mdi/mapbox';
import googleOutlined from '@iconify-icons/ant-design/google-outlined';
import googledriveIcon from '@iconify-icons/simple-icons/googledrive';
import businessRuleTask from '@iconify-icons/bpmn/business-rule-task';
import paintBrush from '@iconify-icons/fa-solid/paint-brush';
import bxBot from '@iconify-icons/bx/bx-bot';
import bxsFileJson from '@iconify-icons/bx/bxs-file-json';
import editOutlined from '@iconify-icons/ant-design/edit-outlined';
import deleteOutlined from '@iconify-icons/ant-design/delete-outlined';

export const Icons = {
        home: sharpHome,
        about: aboutDotMe,
        skills: settingIcon,
        project: codeSlash,
        contact: mailOutlined,
        facebookIcon : facebookRect,
        githubIcon: githubIcon,
        twitterIcon: twitterSquare,
        linkedinIcon: linkedinRect,
        downArrow: arrowDown,
        javascript: javascriptIcon,
        reactjs:reactjsFill,
        typescrip: typescriptIcon,
        nodejs: bxlNodejs,
        nextjs: nextjsOutline,
        electronjs: electronIcon,
        html: html5Icon,
        css: css3Outline,
        google: googleOutlined,
        gdrive: googledriveIcon,
        json: bxsFileJson,
        taskboard: businessRuleTask,
        chatbot: bxBot,
        mapbox: bxMap,
        map: mapboxIcon,
        paint: paintBrush,
        editicon : editOutlined,
        delete : deleteOutlined

}
export  const Icon = _Icon;