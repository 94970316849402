import React from 'react'
import { Link } from "gatsby"

import { useRecoilState } from "recoil"

import Slider from "../ui/Swtich"
import { Icons, Icon } from "../ui/Icons"

import State from "../../utils/global/recoil.states"

import "./style.css"

const NavBar = () => {
    const [ theme, _theme ] = useRecoilState(State.theme)
    
    const onThemeChange = (e: boolean) => {
        if(e) {
            _theme("dark")
            localStorage.setItem("theme", "dark")
        }
        else {
            _theme("light")
            localStorage.setItem("theme", "light")
        }
    }
    return (
        <nav>
            <ul 
                className="navbar-wrapper absolute transform flex w-full left-1/2 -translate-x-1/2 flex-row lg:w-auto  lg:top-1/2 lg:left-0 lg:-translate-y-1/2 lg:-translate-x-0 lg:flex-col" 
            >
                <li className="navbar-navs w-1/5 lg:w-auto" title="Home">
                <Link to="/" aria-label="Sukanta portfoilio home page" activeClassName="navlink-active" className="flex py-2 px-.1  justify-center align-middle"> <Icon icon={Icons.home} className="navbar-navicon" /></Link>
                </li>
                <li className="navbar-navs w-1/5 lg:w-auto" title="About">
                <Link to="/about/" aria-label="More about me" activeClassName="navlink-active" className="flex py-2 px-.1  justify-center align-middle"> <Icon icon={Icons.about} className="navbar-navicon" /></Link>
                </li>
                <li className="navbar-navs w-1/5 lg:w-auto" title="Mini Projects" onClick={() => {
                    localStorage.setItem("theme", "light");
                    _theme("light")
                }}>
                    <Link to="/project" aria-label="Mini projects to showcase" activeClassName="navlink-active" className="flex py-2 px-.1  justify-center align-middle"> <Icon icon={Icons.project} className="navbar-navicon" /></Link>
                </li>
                <li className="navbar-navs w-1/5 lg:w-auto" title="Contact">
                    <Link to="/contact" aria-label="Contact me" activeClassName="navlink-active" className="flex py-2 px-.1  justify-center align-middle"> <Icon icon={Icons.contact} className="navbar-navicon" /></Link>
                </li>
                <li className="navbar-navs flex justify-center align-middle w-1/5 lg:w-auto" title="Theme">
                    <Slider checked={theme === "dark"}  onChange={onThemeChange}/>
                </li>
            </ul>
        </nav>
    )
}
export default React.memo(NavBar);