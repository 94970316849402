import React from "react"
import { useRecoilState } from "recoil"

import { globalHistory, useLocation} from "@reach/router"

import Loader from "../../components/ui/Animations/Loader"

import GlobalState from "../../utils/global/recoil.states"

import NavBar from "../Navbar"
import WithScroll from "../ui/CustomScroll"

import 'antd/dist/antd.css';
import "../../utils/global/style/style.css"
import "./style.css"

const backgroundbgArr = [ "/", "/home"]

const Layout = (props: any) => {

  const [ theme, _theme ] = useRecoilState(GlobalState.theme)
  const [loader, _loader] = React.useState(false)

  const location = useLocation()

  React.useEffect(()=>{
    globalHistory.listen((location) => {
      if(location.action == "PUSH" || location.action == "POP"){
          _loader(true);
          setTimeout(()=>{
            _loader(false);
          },1000)
        }
      })
    const theme = localStorage.getItem("theme")
    if(theme){
        _theme(theme || "dark")
    }
  },[])

  const CloneElement = React.cloneElement(props.children, { GlobalState }, null)
  return (
    <>
      {
        !location.pathname.includes("project") && <NavBar />
      } 
      <WithScroll maxHeight="100vh" className={`component  ${(theme  === "dark" || backgroundbgArr.includes(location.pathname)) ? "bgDark" : "bgLight"}`}>
        {
          loader &&
          <div className={`flex justify-center items-center h-full w-full ${theme === "dark" ? "bgDark textLight" : "bgLight textDark"}`}> 
            <Loader show={loader}/>  
          </div>
        }
        {
          !loader && CloneElement
        }
      </WithScroll>
    </>
  )
}
export default React.memo(Layout)
